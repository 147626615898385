import type { Router } from 'vue-router';
import NProgress from 'nprogress'; // progress bar

import usePermission from '@/hooks/permission';
import { useUserStore } from '@/store';
import { appRoutes } from '../routes';
import { NOT_FOUND } from '../constants';

export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to) => {
    const userStore = useUserStore();
    const Permission = usePermission();
    const permissionsAllow = Permission.accessRouter(to);

    NProgress.done();

    if (permissionsAllow) {
      return true;
    }
    else {
      const destination = Permission.findFirstPermissionRoute(appRoutes, userStore.user.roles) || NOT_FOUND;

      return destination;
    }
  });
}
