import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconStamp } from "@arco-design/web-vue/es/icon";
import type { RouteLocation } from 'vue-router';

const routes: AppRouteRecordRaw = {
  path: '/law',
  redirect: '/law/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '开源法律',
    icon: IconStamp,
    order: 400,
    roles: ['lawyer'],
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/law/index.vue'),
      meta: {
        title: '内容列表',
      },
    },
    {
      path: 'add/:id?',
      component: () => import('@/views/law/add-edit.vue'),
      props: (route: RouteLocation) => ({ type: 'add', id: route.params.id }),
      meta: {
        title: '新增内容',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/law/index',
      },
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/law/add-edit.vue'),
      props: (route: RouteLocation) => ({ type: 'edit', id: route.params.id }),
      meta: {
        title: '修改内容',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/law/index',
      },
    },

    {
      path: 'category/index',
      component: () => import('@/views/law/category/index.vue'),
      meta: {
        title: '法律分类',
      },
    },
    {
      path: 'category/add/:type/:id?',
      component: () => import('@/views/law/category/add-edit.vue'),
      props: (route: RouteLocation) => ({ type: 'add', dataType: route.params.type, id: route.params.id }),
      meta: {
        title: '新增法律分类',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/law/category/index',
      },
    },
    {
      path: 'category/edit/:type/:id',
      component: () => import('@/views/law/category/add-edit.vue'),
      props: (route: RouteLocation) => ({ type: 'edit', dataType: route.params.type, id: route.params.id }),
      meta: {
        title: '修改法律分类',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/law/category/index',
      },
    },
  ],
};

export default routes;
