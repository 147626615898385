import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconEmail } from "@arco-design/web-vue/es/icon";

const routes: AppRouteRecordRaw = {
  path: '/subscription',
  redirect: '/subscription/user/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '订阅管理',
    icon: IconEmail,
    order: 700,
    roles: ['subscriber'],
  },
  children: [
    {
      path: 'user/index',
      component: () => import('@/views/subscription/user/index.vue'),
      meta: {
        title: '用户管理',
      },
    },
    {
      path: 'user/send-history',
      component: () => import('@/views/subscription/user/send-history.vue'),
      meta: {
        title: '发送历史',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/subscription/user/index',
      },
    },
    {
      path: 'user/import-log',
      component: () => import('@/views/subscription/user/import-log.vue'),
      meta: {
        title: '导入日志',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/subscription/user/index',
      },
    },

    {
      path: 'template/index',
      component: () => import('@/views/subscription/template/index.vue'),
      meta: {
        title: '邮件模板',
      },
    },
    {
      path: 'template/add',
      component: () => import('@/views/subscription/template/add-edit.vue'),
      meta: {
        title: '新增模版',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/subscription/template/index',
      },
    },
    {
      path: 'template/edit/:id',
      component: () => import('@/views/subscription/template/add-edit.vue'),
      props: true,
      meta: {
        title: '修改模版',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/subscription/template/index',
      },
    },
  ],
};

export default routes;
