import type { Router } from 'vue-router';

import NProgress from 'nprogress'; // progress bar
import { useUserStore } from '@/store';
import { isLogin, gotoLogin, isAuthCode } from '@/utils/auth';
import { LOGIN_ROUTE_PATH } from '@/router/constants';
import { Modal } from '@arco-design/web-vue';

export default function setupUserLoginInfoGuard(router: Router) {
  router.beforeEach(async (to) => {
    NProgress.start();

    const userStore = useUserStore();

    if (isLogin()) {
      if (userStore.user.userId) {
        return true;
      }
      else {
        const { isSuccess, msg, code } = await userStore.fetchInfo();

        if (!isSuccess) {
          await userStore.logout();

          NProgress.done();

          if (!isAuthCode(code)) {
            Modal.error({
              title: '获取用户信息失败',
              content: msg || '请联系管理员或尝试重新登录',
              okText: '重新登录',
              maskClosable: false,
              escToClose: false,
              closable: false,
              onOk() {
                gotoLogin(to.path);
              },
            });
          }
        }

        return isSuccess;
      }
    }
    else {
      if (to.path === LOGIN_ROUTE_PATH) {
        return true;
      }

      NProgress.done();

      gotoLogin(to.path);

      return false;
    }
  });
}
