import { type App } from 'vue';
import PageSection from './page-section/index.vue';
import Breadcrumb from './breadcrumb/index.vue';
import GeneralCard from './general-card/index.vue';
import SearchTable from './search-table/index.vue';
import RouterButton from './router-button/index.vue';
import { Modal } from '@arco-design/web-vue';
import Upload from './upload/index.vue';
import Image from './image/index.vue';
import LoadingProvider from './loading-provider/index.vue';

export default {
  install(Vue: App) {
    Vue.component(PageSection.name as string, PageSection);
    Vue.component(Breadcrumb.name as string, Breadcrumb);
    Vue.component(GeneralCard.name as string, GeneralCard);
    Vue.component(SearchTable.name as string, SearchTable);
    Vue.component(RouterButton.name as string, RouterButton);
    Vue.component(Upload.name as string, Upload);
    Vue.component(Image.name as string, Image);
    Vue.component(LoadingProvider.name as string, LoadingProvider);

    Vue.use(Modal);
  },
};
