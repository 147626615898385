import { ref, inject } from 'vue';

export default function useLoadingCount() {
  const loadingCountDefault = ref(0);
  const loadingCount = inject('component-loading-count', loadingCountDefault);

  const increase = () => {
    loadingCount.value++;

    return loadingCount.value;
  };

  const decrease = () => {
    if (loadingCount.value > 0) {
      loadingCount.value--;
    }

    return loadingCount.value;
  };

  return {
    increase,
    decrease,
  };
}
