import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconFire } from "@arco-design/web-vue/es/icon";

const routes: AppRouteRecordRaw = {
  path: '/conference',
  redirect: '/conference/home/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '会议管理',
    icon: IconFire,
    order: 900,
    roles: ['coordinator'],
  },
  children: [
    {
      path: 'home/index',
      component: () => import('@/views/conference/home/index.vue'),
      meta: {
        title: '会议列表',
        roles: ['admin'],
      },
    },
    {
      path: 'home/add',
      component: () => import('@/views/conference/home/add-edit.vue'),
      meta: {
        title: '新增会议',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/home/index',
        roles: ['admin'],
      },
    },
    {
      path: 'home/edit/:id',
      component: () => import('@/views/conference/home/add-edit.vue'),
      props: true,
      meta: {
        title: '修改会议',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/home/index',
        roles: ['admin'],
      },
    },

    {
      path: 'detail/edit',
      component: () => import('@/views/conference/home/add-edit.vue'),
      props: () => ({ pageType: 'detail' }),
      meta: {
        title: '会议信息',
      },
    },

    {
      path: 'forum/index',
      component: () => import('@/views/conference/forum/index.vue'),
      meta: {
        title: '论坛列表',
      },
    },
    {
      path: 'forum/add',
      component: () => import('@/views/conference/forum/add-edit.vue'),
      meta: {
        title: '新增论坛',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/forum/index',
      },
    },
    {
      path: 'forum/edit/:id',
      component: () => import('@/views/conference/forum/add-edit.vue'),
      props: true,
      meta: {
        title: '修改论坛',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/forum/index',
      },
    },

    {
      path: 'schedule/index/:id?',
      component: () => import('@/views/conference/schedule/index.vue'),
      props: true,
      meta: {
        title: '议程列表',
        activeMenu: '/conference/schedule/index',
      },
    },
    {
      path: 'schedule/add/:fid?',
      props: true,
      component: () => import('@/views/conference/schedule/add-edit.vue'),
      meta: {
        title: '新增议程',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/schedule/index',
      },
    },
    {
      path: 'schedule/edit/:id/:fid?',
      component: () => import('@/views/conference/schedule/add-edit.vue'),
      props: true,
      meta: {
        title: '修改议程',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/schedule/index',
      },
    },

    {
      path: 'banner/index',
      component: () => import('@/views/conference/banner/index.vue'),
      meta: {
        title: 'Banner 列表',
      },
    },
    {
      path: 'banner/add',
      component: () => import('@/views/conference/banner/add-edit.vue'),
      meta: {
        title: '新增 Banner',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/banner/index',
      },
    },
    {
      path: 'banner/edit/:id',
      component: () => import('@/views/conference/banner/add-edit.vue'),
      props: true,
      meta: {
        title: '修改 Banner',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/banner/index',
      },
    },

    {
      path: 'speaker/index',
      component: () => import('@/views/conference/speaker/index.vue'),
      meta: {
        title: '嘉宾列表',
      },
    },
    {
      path: 'speaker/add',
      component: () => import('@/views/conference/speaker/add-edit.vue'),
      meta: {
        title: '新增嘉宾',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/speaker/index',
      },
    },
    {
      path: 'speaker/edit/:id',
      component: () => import('@/views/conference/speaker/add-edit.vue'),
      props: true,
      meta: {
        title: '修改嘉宾',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/speaker/index',
      },
    },

    {
      path: 'picture/index',
      component: () => import('@/views/conference/picture/index.vue'),
      meta: {
        title: '生态交流区',
      },
    },
    {
      path: 'picture/add',
      component: () => import('@/views/conference/picture/add-edit.vue'),
      meta: {
        title: '新增图片',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/picture/index',
      },
    },
    {
      path: 'picture/edit/:id',
      component: () => import('@/views/conference/picture/add-edit.vue'),
      props: true,
      meta: {
        title: '编辑图片',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/picture/index',
      },
    },

    {
      path: 'logo/index',
      component: () => import('@/views/conference/logo/index.vue'),
      meta: {
        title: 'LOGO 列表',
      },
    },
    {
      path: 'logo/add',
      component: () => import('@/views/conference/logo/add-edit.vue'),
      meta: {
        title: '新增 LOGO',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/logo/index',
      },
    },
    {
      path: 'logo/edit/:id',
      component: () => import('@/views/conference/logo/add-edit.vue'),
      props: true,
      meta: {
        title: '修改 LOGO',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/conference/logo/index',
      },
    },

  ],
};

export default routes;
