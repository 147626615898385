<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

defineOptions({
  name: 'OpenGeneralCard',
});

const props = defineProps({
  title: {
    type: String,
    default: '',
  },

  showI18n: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const router = useRouter();

const goto = (code = '') => {
  router.push({
    path: '/redirect/to',
    query: {
      path: route.fullPath,
      other: code ? JSON.stringify({ __system_language_code__: code }) : '',
    },
  });
}

const currentLanguageCode = computed(() => {
  return route.query.__system_language_code__ === 'en' ? 'en' : 'zh';
});
</script>

<template>
  <ACard class="open-general-card" :title="`${props.title || route.meta.title}${props.showI18n ? `【${currentLanguageCode === 'en' ? '英文' : '中文'}内容】` : ''}`">
    <template #extra>
      <template v-if="props.showI18n">
        <AButton size="small" @click="goto('en')" v-if="currentLanguageCode === 'zh'">
          <template #icon>
            <IconLanguage />
          </template>
          切换到英文内容
        </AButton>
        <AButton size="small" @click="goto()" v-else-if="currentLanguageCode === 'en'">
          <template #icon>
            <IconLanguage />
          </template>
          切换到中文内容
        </AButton>
      </template>
    </template>

    <slot></slot>
  </ACard>
</template>

<style lang="scss" scoped>
.open-general-card {
  border: none;
  border-radius: 4px;

  :deep() {
    & > .arco-card-header {
      height: auto;
      padding: 20px;
      border: none;
    }

    & > .arco-card-body {
      padding: 0 20px 20px 20px;
    }
  }
}
</style>
