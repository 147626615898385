import type { RouteRecordRaw } from 'vue-router';
import { REDIRECT_ROUTE_PATH } from '../constants';

export const DEFAULT_LAYOUT = () => import('@/layout/default-layout.vue');
export const PAGE_LAYOUT = () => import('@/layout/page-layout.vue');

export const REDIRECT_MAIN: RouteRecordRaw = {
  path: '/redirect',
  redirect: REDIRECT_ROUTE_PATH,
  component: DEFAULT_LAYOUT,
  meta: {
    hideInMenu: true,
  },
  children: [
    {
      path: REDIRECT_ROUTE_PATH,
      component: () => import('@/views/redirect/index.vue'),
      props: route => ({ path: route.query.path, other: route.query.other }),
      meta: {
        hideInMenu: true,
        ignoreCache: true,
      },
    },
  ],
};

export const NOT_FOUND_ROUTE: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  name: 'notFound',
  component: () => import('@/views/not-found/index.vue'),
};
