import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconBook } from "@arco-design/web-vue/es/icon";

const routes: AppRouteRecordRaw = {
  path: '/journalism',
  redirect: '/journalism/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '新闻发布',
    icon: IconBook,
    order: 200,
    roles: ['newser'],
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/journalism/index.vue'),
      meta: {
        title: '新闻动态',
      },
    },
    {
      path: 'add',
      component: () => import('@/views/journalism/add-edit.vue'),
      meta: {
        title: '新增新闻',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/journalism/index',
      },
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/journalism/add-edit.vue'),
      props: true,
      meta: {
        title: '修改新闻',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/journalism/index',
      },
    },

    {
      path: 'category/index',
      component: () => import('@/views/journalism/category/index.vue'),
      meta: {
        title: '新闻分类',
        roles: ['admin'],
      },
    },
    {
      path: 'category/add',
      component: () => import('@/views/journalism/category/add-edit.vue'),
      meta: {
        title: '新增新闻分类',
        roles: ['admin'],
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/journalism/category/index',
      },
    },
    {
      path: 'category/edit/:id',
      component: () => import('@/views/journalism/category/add-edit.vue'),
      props: true,
      meta: {
        title: '修改新闻分类',
        roles: ['admin'],
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/journalism/category/index',
      },
    },
  ],
};

export default routes;
