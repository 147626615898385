<script lang="ts" setup>
import { provide, ref } from 'vue';

const loadingCount = ref(0);

defineOptions({
  name: 'OpenLoadingProvider',
});

provide('component-loading-count', loadingCount);
</script>

<template>
  <slot :loading="loadingCount > 0"></slot>
</template>

<style lang="scss" scoped>
</style>
