import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconPublic } from "@arco-design/web-vue/es/icon";

const routes: AppRouteRecordRaw = {
  path: '/insight',
  redirect: '/insight/issue/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '开源洞察',
    icon: IconPublic,
    order: 450,
    roles: ['insighter'],
  },
  children: [
    {
      path: 'issue/index',
      component: () => import('@/views/insight/issue/index.vue'),
      meta: {
        title: '期刊列表',
      },
    },
    {
      path: 'issue/add',
      component: () => import('@/views/insight/issue/add-edit.vue'),
      meta: {
        title: '新增期刊',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/insight/issue/index',
      },
    },
    {
      path: 'issue/edit/:id',
      component: () => import('@/views/insight/issue/add-edit.vue'),
      props: true,
      meta: {
        title: '修改期刊',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/insight/issue/index',
      },
    },

    {
      path: 'article/index',
      component: () => import('@/views/insight/article/index.vue'),
      meta: {
        title: '文章列表',
      },
    },
    {
      path: 'article/add',
      component: () => import('@/views/insight/article/add-edit.vue'),
      meta: {
        title: '新增文章',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/insight/article/index',
      },
    },
    {
      path: 'article/edit/:id',
      component: () => import('@/views/insight/article/add-edit.vue'),
      props: true,
      meta: {
        title: '修改文章',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/insight/article/index',
      },
    },
  ],
};

export default routes;
