import { LOGIN_ROUTE_PATH } from '@/router/constants';
import { $fetch } from '@/utils/fetch';

const TOKEN_KEY = 'openatom-api-token';
const AUTH_CODE = [100101, 100102, 101001];

const base = `${window.location.protocol}//${window.location.host}`;

export const isLogin = () => {
  if (import.meta.env.VITE_MOCK_LOGIN === 'yes') {
    return true;
  }
  else {
    return !!localStorage.getItem(TOKEN_KEY);
  }
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const gotoLogin = async (from = '/') => {
  const { isSuccess, data } = await $fetch('/auth/v1/login/info', {
    method: 'get',
    data: {
      redirect: `${base}${LOGIN_ROUTE_PATH}?from=${encodeURIComponent(from)}`,
    },
  });

  if (isSuccess) {
    window.location.href = data.loginUrl;
  }
};

export const getLoginRedirect = () => {
  const query = new URLSearchParams(window.location.search);
  const from = query.get('from');

  return `${base}${LOGIN_ROUTE_PATH}${from ? `?from=${encodeURIComponent(from)}` : ''}`;
};

export const isAuthCode = (code: number) => AUTH_CODE.includes(code);
