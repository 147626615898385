import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconUserGroup } from "@arco-design/web-vue/es/icon";
import type { RouteLocation } from 'vue-router';

const routes: AppRouteRecordRaw = {
  path: '/partner',
  redirect: '/partner/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '合作',
    icon: IconUserGroup,
    order: 500,
    roles: ['partner'],
  },
  children: [
    {
      path: 'donor/index',
      component: () => import('@/views/partner/donor/index.vue'),
      meta: {
        title: '捐赠人列表',
      },
    },

    {
      path: 'donor/category/index',
      component: () => import('@/views/partner/donor/category/index.vue'),
      meta: {
        title: '捐赠人分类',
      },
    },
    {
      path: 'donor/category/add/:type/:id?',
      component: () => import('@/views/partner/donor/category/add-edit.vue'),
      props: (route: RouteLocation) => ({ type: 'add', dataType: route.params.type, id: route.params.id }),
      meta: {
        title: '新增捐赠人分类',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/partner/donor/category/index',
      },
    },
    {
      path: 'donor/category/edit/:type/:id',
      component: () => import('@/views/partner/donor/category/add-edit.vue'),
      props: (route: RouteLocation) => ({ type: 'edit', dataType: route.params.type, id: route.params.id }),
      meta: {
        title: '修改捐赠人分类',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/partner/donor/category/index',
      },
    },
  ],
};

export default routes;
