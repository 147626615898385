import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconHome } from "@arco-design/web-vue/es/icon";
import { DEFAULT_ROUTE_TITLE } from '../../constants';

const routes: AppRouteRecordRaw = {
  path: '/welcome',
  redirect: '/welcome/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: DEFAULT_ROUTE_TITLE,
    icon: IconHome,
    hideChildrenInMenu: true,
    order: 0,
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/welcome/index.vue'),
      meta: {
        title: '首页',
      },
    },
  ],
};

export default routes;
