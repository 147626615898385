import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconFile } from "@arco-design/web-vue/es/icon";

const routes: AppRouteRecordRaw = {
  path: '/project',
  redirect: '/project/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '项目管理',
    icon: IconFile,
    order: 100,
    roles: ['projector'],
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/project/index.vue'),
      meta: {
        title: '项目列表',
      },
    },
    {
      path: 'add',
      component: () => import('@/views/project/add-edit.vue'),
      meta: {
        title: '新增项目',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/project/index',
      },
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/project/add-edit.vue'),
      props: true,
      meta: {
        title: '修改项目',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/project/index',
      },
    },
    {
      path: 'view/:id',
      component: () => import('@/views/project/view.vue'),
      props: true,
      meta: {
        title: '查看项目',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/project/index',
      },
    },
  ],
};

export default routes;
