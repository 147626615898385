import { type App } from 'vue';
import permission from './permission';
import confirm from './confirm';

export default {
  install(Vue: App) {
    Vue.directive('permission', permission);
    Vue.directive('confirm', confirm);
  },
};
