import type { DirectiveBinding, VNode, SetupContext, FunctionDirective } from 'vue';
import { Modal, type ModalConfig } from '@arco-design/web-vue';

export type MySetupContext = SetupContext & {
  __adminHookHandleClick: boolean;
  __adminHookHandleSubmit: boolean;
  setupState: any;
}

export interface MyVNode extends VNode {
  ctx: MySetupContext;
}

const directive = (el: HTMLElement, binding: DirectiveBinding, vnode: MyVNode) => {
  const newHandle = function(oldHandle: (event: Event) => void, setupState?: any) {
    return async function(event: Event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }

      if (setupState) {
        const res = await setupState.innerValidate();
        if (res) {
          return;
        }
      }

      const modalConfig: ModalConfig = {
        title: '操作确认',
        content: '确认执行这个操作吗？',
        simple: true,
        okText: '确认',
      };

      if (typeof binding.value === 'string') {
        modalConfig.title = binding.value;
      }
      else if (typeof binding.value === 'object') {
        if (binding.value.title) {
          modalConfig.title = binding.value.title;
        }
        if (binding.value.content) {
          modalConfig.content = binding.value.content;
        }
      }

      modalConfig.onOk = () => {
        oldHandle(event);
      };

      Modal.confirm(modalConfig);
    };
  };

  if (vnode.ctx.setupState.handleClick && !vnode.ctx.__adminHookHandleClick) {
    const oldHandle = vnode.ctx.setupState.handleClick;

    vnode.ctx.__adminHookHandleClick = true;
    vnode.ctx.setupState.handleClick = newHandle(oldHandle);
  }

  if (vnode.ctx.setupState.handleSubmit && !vnode.ctx.__adminHookHandleSubmit) {
    const oldHandle = vnode.ctx.setupState.handleSubmit;

    vnode.ctx.__adminHookHandleSubmit = true;
    vnode.ctx.setupState.handleSubmit = newHandle(oldHandle, vnode.ctx.setupState);
  }
}

export default directive as unknown as FunctionDirective;
