import { createRouter, createWebHistory } from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';

import { appRoutes, getFlatRoutes } from './routes';
import { REDIRECT_MAIN, NOT_FOUND_ROUTE } from './routes/base';
import { DEFAULT_ROUTE_PATH, LOGIN_ROUTE_PATH } from './constants';
import createRouteGuard from './guard';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const flatRoutes = getFlatRoutes(appRoutes);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: DEFAULT_ROUTE_PATH,
    },
    {
      path: LOGIN_ROUTE_PATH,
      component: () => import('@/views/login/index.vue'),
      props: route => ({
        code: route.query.code,
        from: route.query.from,
      }),
      meta: {
        notAuth: true,
      },
    },
    ...flatRoutes,
    REDIRECT_MAIN,
    NOT_FOUND_ROUTE,
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

createRouteGuard(router);

export default router;
