import { type DirectiveBinding } from 'vue';
import { useUserStore } from '@/store';

function checkPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { value } = binding;
  const userStore = useUserStore();
  const { roles } = userStore.user;

  if (Array.isArray(value)) {
    if (value.length > 0) {
      const permissionValues = value;
      const hasPermission = (roles.includes('admin') || roles.some(role => permissionValues.includes(role)));

      if (!hasPermission && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  }
  else {
    throw new Error(`need roles! Like v-permission="['admin','user']"`);
  }
}

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
};
