<script lang="ts" setup>
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn';

zhCN.datePicker.ok = '确认';
</script>

<template>
  <AConfigProvider :locale="zhCN">
    <RouterView />
  </AConfigProvider>
</template>
