import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconBookmark } from "@arco-design/web-vue/es/icon";

const routes: AppRouteRecordRaw = {
  path: '/information',
  redirect: '/information/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '信息公开',
    icon: IconBookmark,
    order: 600,
    roles: ['exter', 'insighter'],
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/information/common/index.vue'),
      meta: {
        title: '信息列表',
        roles: ['exter'],
      },
    },
    {
      path: 'add',
      component: () => import('@/views/information/common/add-edit.vue'),
      meta: {
        title: '新增信息',
        roles: ['exter'],
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/information/index',
      },
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/information/common/add-edit.vue'),
      props: true,
      meta: {
        title: '修改信息',
        roles: ['exter'],
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/information/index',
      },
    },

    {
      path: 'insight/index',
      component: () => import('@/views/information/insight/index.vue'),
      meta: {
        title: '开源洞察',
        roles: ['insighter'],
      },
    },
    {
      path: 'insight/add',
      component: () => import('@/views/information/insight/add-edit.vue'),
      meta: {
        title: '新增开源洞察',
        roles: ['insighter'],
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/information/insight/index',
      },
    },
    {
      path: 'insight/edit/:id',
      component: () => import('@/views/information/insight/add-edit.vue'),
      props: true,
      meta: {
        title: '修改开源洞察',
        roles: ['insighter'],
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/information/insight/index',
      },
    },
  ],
};

export default routes;
