<script lang="ts" setup>
import 'cropperjs/dist/cropper.css';

import { ref, nextTick, watch, computed, type PropType } from 'vue';
import Cropper from 'cropperjs';
import type { Emitter, EventType } from 'mitt';
import { Notification } from '@arco-design/web-vue';

defineOptions({
  name: 'OpenCropperDialog',
});

const props = defineProps({
  url: {
    type: String,
    default: '',
  },

  emitter: {
    type: Object as PropType<Emitter<Record<EventType, string | unknown>>>,
    default: null,
  },

  cropRatio: {
    type: Number,
    default: NaN,
  },
});

const loading = ref(true);
const loaded = ref(false);
const fullscreen = ref(false);
const cutStyle = ref('rectangle');
const dragMode = ref<Cropper.DragMode>('move');
const image = ref();
const aspectRatio = ref(0);
const cropData = ref<Cropper.Data>({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotate: 0,
  scaleX: 0,
  scaleY: 0,
});
const invalid = ref(false);
let cropperInstance: Cropper;

const emits = defineEmits(['ok', 'close']);

const imageUrl = computed(() => {
  return props.url.split('?')[0];
});

const imageUrlParams = computed(() => {
  return props.url.split('?')[1] ?? '';
});

const parseData = (params: string) => {
  if (!/x-oss-process=/i.test(params)) {
    return;
  }

  const ossProcess = params.replace('x-oss-process=', '').split('/');

  if (ossProcess[0] !== 'image') {
    return;
  }

  const remapOssProcess = ossProcess.map(item => item.split(','));

  let imageParams;
  const cropData: Record<string, string> = {};
  let rotateData;
  let circleData;

  remapOssProcess.forEach((item) => {
    switch (item[0]) {
      case 'crop':
        imageParams = item.map(value => value.split('_'));
        imageParams.forEach((value) => {
          if (value[0] !== 'crop') {
            cropData[value[0]] = value[1];
          }
        });
        break

      case 'rotate':
        rotateData = item[1];
        break

      case 'circle':
        circleData = item[1].split('_')[1];
        break;

      default:
        break;
    }
  });

  return {
    crop: cropData,
    rotate: rotateData,
    circle: circleData,
  };
}

const cropInit = () => {
  // 初始化这个裁剪框
  cropperInstance = new Cropper(image.value, {
    // aspectRatio: self.aspectRatio.w / self.aspectRatio.h,
    aspectRatio: isNaN(props.cropRatio) ? aspectRatio.value : props.cropRatio,
    viewMode: 0,
    scalable: true,
    zoomable: true,
    zoomOnTouch: true,
    zoomOnWheel: true,
    wheelZoomRatio: 0.1,
    center: true,
    movable: true,
    dragMode: dragMode.value,
    // autoCropArea: 1,
    ready() {
      loading.value = false;
      loaded.value = true;

      const data = parseData(imageUrlParams.value);

      if (data) {
        if (data.circle) {
          cutStyle.value = 'circle';
        }

        nextTick(() => {
          (this as (HTMLImageElement & { cropper: Cropper })).cropper.setData({
            x: Number(data.crop.x),
            y: Number(data.crop.y),
            width: Number(data.crop.w),
            height: Number(data.crop.h),
            rotate: Number(data.rotate),
          });
        });
      }
    },
    crop(e) {
      // console.log(e.detail)
      // let position = {'x': e.detail.x, 'y': e.detail.y, 'w': e.detail.width, 'h': e.detail.height}
      cropData.value = e.detail;
    },
  })
};

const zoom = (type: string) => {
  if (type == 'in') {
    cropperInstance.zoom(0.1);
  }
  else if (type == 'out') {
    cropperInstance.zoom(-0.1);
  }
};

const rotate = (type: string) => {
  if (type == '+') {
    cropperInstance.rotate(10);
  }
  else if (type == '-') {
    cropperInstance.rotate(-10);
  }
};

const moveTo = (type: string) => {
  if (type == 'up') {
    cropperInstance.move(0, -10);
  }
  else if (type == 'down') {
    cropperInstance.move(0, 10);
  }
  else if (type == 'left') {
    cropperInstance.move(-10, 0);
  }
  else if (type == 'right') {
    cropperInstance.move(10, 0);
  }
};

const setAspectRatio = (ratio: number) => {
  cropperInstance.setAspectRatio(ratio);
};

const setCutStyle = (type: string) => {
  const el: HTMLElement | null = document.querySelector('.cropper-view-box');

  if (!el) {
    return;
  }

  if (type == 'circle') {
    el.style.cssText = 'border-radius: 50%;';
    setAspectRatio(1);
  }
  else {
    el.style.cssText = 'border-radius: 0;';
    setAspectRatio(aspectRatio.value);
  }
};

const reset = async () => {
  cropperInstance.reset();
  cutStyle.value = 'rectangle';

  await nextTick();

  if (aspectRatio.value === 0 && isNaN(props.cropRatio)) {
    const imageData = cropperInstance.getImageData();
    const containerData = cropperInstance.getContainerData();

    cropperInstance.setCropBoxData({
      left: ((containerData.width - imageData.width) / 2),
      top: ((containerData.height - imageData.height) / 2),
      width: imageData.width,
      height: imageData.height,
    });
  }
};

// const getImageData = () => {
//   const data = cropperInstance.getImageData();
//   console.log(data);
// };

watch(aspectRatio, (n) => {
  setAspectRatio(n);
});

watch(cutStyle, (n) => {
  setCutStyle(n);
});

watch(dragMode, (n) => {
  cropperInstance.setDragMode(n);
});

const beforeOk = () => {
  const imageData = cropperInstance.getImageData();
  const data = cropData.value;
  const endX = data.x + data.width;
  const endY = data.y + data.height;

  if ((data.x < 0 || data.y < 0 || endX > imageData.naturalWidth || endY > imageData.naturalHeight)) {
    Notification.warning('裁剪区域不能超出图像！');
    return false;
  }

  return true;
}

const save = () => {
  if (cropData.value.rotate < 0) {
    cropData.value.rotate = 360 + cropData.value.rotate;
  }

  let url;

  if (cutStyle.value == 'circle') {
    url = `${imageUrl.value}?x-oss-process=image/rotate,${cropData.value.rotate}/crop,x_${Math.floor(cropData.value.x)},y_${Math.floor(cropData.value.y)},w_${Math.floor(cropData.value.width)},h_${Math.floor(cropData.value.height)}/circle,r_${Math.floor(cropData.value.width / 2)}`;
  }
  else {
    url = `${imageUrl.value}?x-oss-process=image/rotate,${cropData.value.rotate}/crop,x_${Math.floor(cropData.value.x)},y_${Math.floor(cropData.value.y)},w_${Math.floor(cropData.value.width)},h_${Math.floor(cropData.value.height)}`;
  }

  emits('ok', url, cropData.value, cutStyle.value);
  props.emitter?.emit('ok', url);
};

const close = () => {
  cropperInstance?.destroy();
  emits('close');
  props.emitter?.emit('close');
};

const toggleFullscreen = async () => {
  fullscreen.value = !fullscreen.value;

  await nextTick();

  (cropperInstance as any).resize();
  cropperInstance.reset();
};

const init = () => {
  if (
    (!/^https:\/\/.+?\.oafimg\.cn\//.test(props.url) && !/^https:\/\/.+?\.aliyuncs\.com\//.test(props.url)) ||
    !/\.(?:jpeg|jpg|png|bmp|gif|tiff|heic|avif)(?:$|(\?.*)$)/i.test(props.url)
  ) {
    loading.value = false;
    invalid.value = true;
    return;
  }

  cropInit();
};
</script>

<template>
  <AModal
    class="open-cropper-dialog"
    :title="`裁剪图片${!isNaN(props.cropRatio) ? '【固定比例模式】' : ''}`"
    :width="'1100px'"
    body-class="h-full"
    :fullscreen="fullscreen"
    default-visible
    :mask-closable="false"
    ok-text="确认"
    :ok-loading="loading"
    :ok-button-props="{ disabled: !loaded }"
    :on-before-ok="beforeOk"
    @open="init"
    @ok="save"
    @close="close"
  >
    <div class="h-full">
      <div class="crop-dialog-inner">
        <div class="card-warp relative">
          <img :src="imageUrl" ref="image" @error="loading = false">
          <ASpin :size="32" class="absolute left-50% top-50% translate--50%" v-if="loading" />
          <AAlert type="warning" v-if="invalid">只支持裁剪通过后台上传的图片，合法格式为 jpeg、jpg、png、bmp、gif</AAlert>
        </div>
        <div class="control-wrap">
          <AForm auto-label-width :model="{}" :disabled="!loaded">
            <AFormItem label="裁剪样式" :disabled="!isNaN(props.cropRatio) || !loaded">
              <ARadioGroup v-model="cutStyle" size="mini">
                <ARadio value="rectangle">矩形</ARadio>
                <ARadio value="circle">圆形</ARadio>
              </ARadioGroup>
            </AFormItem>
            <AFormItem label="剪切框外行为" :disabled="!isNaN(props.cropRatio) || !loaded">
              <ARadioGroup v-model="dragMode" size="mini">
                <ARadio value="move">图片拖动</ARadio>
                <ARadio value="crop">剪切框</ARadio>
                <ARadio value="none">无</ARadio>
              </ARadioGroup>
            </AFormItem>
            <AFormItem label="图片缩放">
              <ASpace>
                <AButton @click="zoom('in')" type="primary" size="small" shape="circle"><IconZoomIn /></AButton>
                <AButton @click="zoom('out')" type="primary" size="small" shape="circle"><IconZoomOut /></AButton>
              </ASpace>
            </AFormItem>
            <AFormItem label="图片旋转">
              <ASpace>
                <AButton @click="rotate('+')" type="primary" size="small" shape="circle"><IconPlus /></AButton>
                <AButton @click="rotate('-')" type="primary" size="small" shape="circle"><IconMinus /></AButton>
              </ASpace>
            </AFormItem>
            <AFormItem label="图片偏移">
              <ASpace>
                <AButton @click="moveTo('up')" type="primary" size="small" shape="circle"><IconArrowUp /></AButton>
                <AButton @click="moveTo('down')" type="primary" size="small" shape="circle"><IconArrowDown /></AButton>
                <AButton @click="moveTo('left')" type="primary" size="small" shape="circle"><IconArrowLeft /></AButton>
                <AButton @click="moveTo('right')" type="primary" size="small" shape="circle"><IconArrowRight /></AButton>
              </ASpace>
            </AFormItem>
            <AFormItem label="宽高比" :disabled="!isNaN(props.cropRatio) || !loaded">
              <ARadioGroup v-model="aspectRatio" size="mini" :disabled="cutStyle == 'circle' || !loaded">
                <ARadio :value="16 / 9">16:9</ARadio>
                <ARadio :value="4 / 3">4:3</ARadio>
                <ARadio :value="2 / 3">2:3</ARadio>
                <ARadio :value="1 / 1">1:1</ARadio>
                <ARadio :value="0">{{ isNaN(props.cropRatio) ? '无' : '固定' }}</ARadio>
              </ARadioGroup>
            </AFormItem>
            <AFormItem label="重置">
              <AButton @click="reset" type="primary" size="small" shape="circle"><IconRefresh /></AButton>
            </AFormItem>
            <AFormItem label="">
              <AButton size="small" @click="toggleFullscreen">{{ fullscreen ? '还原窗口' : '窗口最大化' }}</AButton>
            </AFormItem>
          </AForm>
        </div>
      </div>
    </div>
  </AModal>
</template>

<style lang="scss" scoped>
.open-cropper-dialog {
  .card-warp {
    display: block;
    width: 70%;

    img {
      display: none;
      max-width: 100%;
      height: 100%;
    }
  }

  .control-wrap {
    display: inline-block;
    box-sizing: border-box;
    width: 30%;
    padding: 20px 0 0 20px;
    vertical-align: top;
  }

  .crop-dialog-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
  }
}
</style>
