import { type RouteLocationNormalized, type RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/store';
import type { RoleType } from '@/store/modules/user/types';

export default function usePermission() {
  const userStore = useUserStore();

  return {
    accessRouter(route: RouteLocationNormalized | RouteRecordRaw) {
      return (
        userStore.user.roles.includes('admin') ||
        route.meta?.notAuth ||
        !route.meta?.roles ||
        route.meta?.roles?.includes('*') ||
        userStore.user.roles.some(role => route.meta?.roles?.includes(role))
      );
    },

    findFirstPermissionRoute(_routers: RouteRecordRaw[], roles: RoleType[] = []) {
      const cloneRouters = [..._routers];

      while (cloneRouters.length) {
        const firstElement = cloneRouters.shift();
        if (
          firstElement?.meta?.notAuth ||
          !firstElement?.meta?.roles ||
          firstElement?.meta?.roles?.includes('*') ||
          firstElement?.meta?.roles?.find((el) => roles.includes(el as RoleType))
        ) {
          return firstElement ? { path: firstElement.path } : null;
        }
        if (firstElement?.children) {
          cloneRouters.push(...firstElement.children);
        }
      }

      return null;
    },
    // You can add any rules you want
  };
}
