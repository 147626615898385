import type { RouteRecordNormalized, RouteRecordRaw } from 'vue-router';
import { cloneDeep } from 'lodash-es';

const modules = import.meta.glob('./modules/*.ts', { eager: true });

function formatModules(_modules: any, result: RouteRecordNormalized[]) {
  Object.keys(_modules).forEach((key) => {
    const defaultModule = _modules[key].default;

    if (!defaultModule) {
      return;
    }
    const moduleList = Array.isArray(defaultModule)
      ? [...defaultModule]
      : [defaultModule];

    result.push(...moduleList);
  });

  return result;
}

// 扁平化路由对象
const flatRoutes = (routerMap: RouteRecordRaw[], maxDepth = 0, depth = 0, parent: RouteRecordRaw = null as never) => {
  let newMap: RouteRecordRaw[] = [];

  routerMap.forEach((item) => {
    if (parent) {
      if (!item.meta) {
        item.meta = {
          __breadcrumb: [],
        };
      }
      else if (!item.meta.__breadcrumb) {
        item.meta.__breadcrumb = [];
      }

      if (parent?.meta?.__breadcrumb) {
        let parentFullPath = '';

        parent.meta.__breadcrumb.forEach((value) => {
          if (value.path.indexOf('/') !== 0) {
            parentFullPath += '/' + value.path;
          }
          else {
            parentFullPath = value.path;
          }
        });

        if (parent.path.indexOf('/') !== 0) {
          parentFullPath += '/' + parent.path;
        }
        else {
          parentFullPath = parent.path;
        }

        item.meta.__breadcrumb = parent.meta.__breadcrumb.concat([{
          path: parentFullPath,
          title: parent?.meta?.title,
        }]);
      }
      else {
        item.meta.__breadcrumb && item.meta.__breadcrumb.push({
          path: parent.path,
          title: parent?.meta?.title,
        });
      }
    }

    if (item.children && item.children.length > 0) {
      const result = flatRoutes(item.children, maxDepth, depth + 1, item);

      if (depth > maxDepth) {
        result.forEach((value) => {
          if (value.path.indexOf('/') !== 0) {
            value.path = `${item.path}/${value.path}`;
          }

          if (value.redirect && String(value.redirect).indexOf('/') !== 0) {
            value.redirect = `${item.path}/${value.redirect}`;
          }
        });

        newMap = newMap.concat(result);
        item.children = undefined;
      }
      else {
        item.children = result;
      }
    }
  });

  return routerMap.concat(newMap);
}

export const getFlatRoutes = (routes: RouteRecordRaw[]) => {
  const copyRoutes = cloneDeep(routes);

  flatRoutes(copyRoutes);

  return copyRoutes;
}

export const appRoutes: RouteRecordNormalized[] = formatModules(modules, []);
