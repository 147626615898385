<script lang="ts" setup>
import { type PropType } from 'vue';

defineOptions({
  name: 'OpenBreadcrumb',
});

const props = defineProps({
  items: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});
</script>

<template>
  <ABreadcrumb class="open-breadcrumb">
    <ABreadcrumbItem>
      <IconApps />
    </ABreadcrumbItem>
    <ABreadcrumbItem v-for="(item, index) in props.items" :key="`${item}-${index}`">
      {{ item }}
    </ABreadcrumbItem>
  </ABreadcrumb>
</template>

<style lang="scss" scoped>
.open-breadcrumb {
  :deep(.arco-breadcrumb-item) {
    color: rgb(var(--gray-6));

    &:last-child {
      color: rgb(var(--gray-8));
    }
  }
}
</style>
