import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconIdcard } from "@arco-design/web-vue/es/icon";

const routes: AppRouteRecordRaw = {
  path: '/certification',
  redirect: '/certification/home/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '人才认证',
    icon: IconIdcard,
    order: 1000,
    roles: ['certifier'],
  },
  children: [
    {
      path: 'category/index',
      component: () => import('@/views/certification/category/index.vue'),
      meta: {
        title: '分类管理',
      },
    },
    {
      path: 'category/add',
      component: () => import('@/views/certification/category/add-edit.vue'),
      meta: {
        title: '新增认证分类',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/category/index',
      },
    },
    {
      path: 'category/edit/:id',
      component: () => import('@/views/certification/category/add-edit.vue'),
      props: true,
      meta: {
        title: '修改认证分类',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/category/index',
      },
    },

    {
      path: 'home/index',
      component: () => import('@/views/certification/home/index.vue'),
      meta: {
        title: '项目管理',
      },
    },
    {
      path: 'home/add',
      component: () => import('@/views/certification/home/add-edit.vue'),
      meta: {
        title: '新增认证项目',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/home/index',
      },
    },
    {
      path: 'home/edit/:id',
      component: () => import('@/views/certification/home/add-edit.vue'),
      props: true,
      meta: {
        title: '修改认证项目',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/home/index',
      },
    },

    {
      path: 'course/index',
      component: () => import('@/views/certification/course/index.vue'),
      meta: {
        title: '课程管理',
      },
    },
    {
      path: 'course/add',
      component: () => import('@/views/certification/course/add-edit.vue'),
      meta: {
        title: '新增课程',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/course/index',
      },
    },
    {
      path: 'course/edit/:id',
      component: () => import('@/views/certification/course/add-edit.vue'),
      props: true,
      meta: {
        title: '修改课程',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/course/index',
      },
    },

    {
      path: 'partner/index',
      component: () => import('@/views/certification/partner/index.vue'),
      meta: {
        title: '合作伙伴管理',
      },
    },
    {
      path: 'partner/add',
      component: () => import('@/views/certification/partner/add-edit.vue'),
      meta: {
        title: '新增合作伙伴',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/partner/index',
      },
    },
    {
      path: 'partner/edit/:id',
      component: () => import('@/views/certification/partner/add-edit.vue'),
      props: true,
      meta: {
        title: '修改合作伙伴',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/certification/partner/index',
      },
    },
  ],
};

export default routes;
