import { createApp } from 'vue'
import globalComponents from '@/components';
import router from './router';
import store from './store';
import directive from './directive';
import App from './App.vue';

import 'lazysizes';

import 'virtual:uno.css';

// 样式通过 arco-plugin 插件导入。详见 vite.config.ts
// https://arco.design/docs/designlab/use-theme-package
import '@/assets/style/global.scss';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(globalComponents);
app.use(directive);

app.mount('#app');
