<script lang="ts" setup>
import { ref } from 'vue';

defineOptions({
  name: 'OpenImage',
});

const props = defineProps({
  src: {
    type: String,
    default: '',
  },

  imgClass: {
    type: [String, Array, Object],
    default: '',
  },
});

const visible = ref(false);
</script>

<template>
  <div class="open-image">
    <img :data-src="props.src" class="lazyload cursor-pointer" :key="props.src" :class="props.imgClass" @click="visible = true" v-if="props.src">
    <AImagePreview :src="props.src" v-model:visible="visible" />
  </div>
</template>

<style lang="scss" scoped>
</style>
