import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconUser } from "@arco-design/web-vue/es/icon";
import type { RouteLocation } from 'vue-router';

const index = () => import('@/views/about/common/index.vue');
const addEdit = () => import('@/views/about/common/add-edit.vue');

const routes: AppRouteRecordRaw = {
  path: '/about',
  redirect: '/about/toc/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '关于我们',
    icon: IconUser,
    order: 800,
    roles: ['exter'],
  },
  children: [
    {
      path: 'council/index',
      component: index,
      props: { pageType: 'council' },
      meta: {
        title: '理事信息',
      },
    },
    {
      path: 'council/add',
      component: addEdit,
      props: { pageType: 'council' },
      meta: {
        title: '添加理事人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/council/index',
      },
    },
    {
      path: 'council/edit/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'council' }),
      meta: {
        title: '修改理事人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/council/index',
      },
    },
    {
      path: 'council/view/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'council', view: true }),
      meta: {
        title: '查看理事人员',
        hideInMenu: true,
        noAffix: true,
        ignoreCache: true,
        activeMenu: '/about/council/index',
      },
    },

    // ----------------------------------------------

    {
      path: 'toc/index',
      component: index,
      props: { pageType: 'toc' },
      meta: {
        title: '技术监督委员会(TOC)',
      },
    },
    {
      path: 'toc/add',
      component: addEdit,
      props: { pageType: 'toc' },
      meta: {
        title: '添加 TOC 人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/toc/index',
      },
    },
    {
      path: 'toc/edit/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'toc' }),
      meta: {
        title: '修改 TOC 人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/toc/index',
      },
    },
    {
      path: 'toc/view/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'toc', view: true }),
      meta: {
        title: '查看 TOC 人员',
        hideInMenu: true,
        noAffix: true,
        ignoreCache: true,
        activeMenu: '/about/toc/index',
      },
    },

    // ----------------------------------------------

    {
      path: 'mentor/index',
      component: index,
      props: { pageType: 'mentor' },
      meta: {
        title: 'TOC 导师团',
      },
    },
    {
      path: 'mentor/add',
      component: addEdit,
      props: { pageType: 'mentor' },
      meta: {
        title: '添加导师团人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/mentor/index',
      },
    },
    {
      path: 'mentor/edit/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'mentor' }),
      meta: {
        title: '修改导师团人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/mentor/index',
      },
    },
    {
      path: 'mentor/view/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'mentor', view: true }),
      meta: {
        title: '查看导师团人员',
        hideInMenu: true,
        noAffix: true,
        ignoreCache: true,
        activeMenu: '/about/mentor/index',
      },
    },

    // ----------------------------------------------

    {
      path: 'security/index',
      component: index,
      props: { pageType: 'security' },
      meta: {
        title: '开源安全委员会',
      },
    },
    {
      path: 'security/add',
      component: addEdit,
      props: { pageType: 'security' },
      meta: {
        title: '添加开源安全委员会人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/security/index',
      },
    },
    {
      path: 'security/edit/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'security' }),
      meta: {
        title: '修改开源安全委员会人员',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/security/index',
      },
    },
    {
      path: 'security/view/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'security', view: true }),
      meta: {
        title: '查看开源安全委员会人员',
        hideInMenu: true,
        noAffix: true,
        ignoreCache: true,
        activeMenu: '/about/security/index',
      },
    },

    // ----------------------------------------------

    {
      path: 'cloud/index',
      component: index,
      props: { pageType: 'cloud' },
      meta: {
        title: '开放原子云社区',
      },
    },
    {
      path: 'cloud/add',
      component: addEdit,
      props: { pageType: 'cloud' },
      meta: {
        title: '添加开放原子云社区',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/cloud/index',
      },
    },
    {
      path: 'cloud/edit/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'cloud' }),
      meta: {
        title: '修改开放原子云社区',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/about/cloud/index',
      },
    },
    {
      path: 'cloud/view/:id',
      component: addEdit,
      props: (route: RouteLocation) => ({ id: route.params.id, pageType: 'cloud', view: true }),
      meta: {
        title: '查看开放原子云社区',
        hideInMenu: true,
        noAffix: true,
        ignoreCache: true,
        activeMenu: '/about/cloud/index',
      },
    },

    // ----------------------------------------------

  ],
};

export default routes;
