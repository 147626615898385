import { DEFAULT_LAYOUT } from '../base';
import { type AppRouteRecordRaw } from '../types';
import { IconCalendar } from "@arco-design/web-vue/es/icon";
import type { RouteLocation } from 'vue-router';

const routes: AppRouteRecordRaw = {
  path: '/events',
  redirect: '/events/index',
  component: DEFAULT_LAYOUT,
  meta: {
    title: '日历活动',
    icon: IconCalendar,
    order: 300,
    roles: ['notifier'],
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/events/index.vue'),
      meta: {
        title: '活动列表',
      },
    },
    {
      path: 'add',
      component: () => import('@/views/events/add-edit.vue'),
      meta: {
        title: '新增活动',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/events/index',
      },
    },
    {
      path: 'edit/:id',
      component: () => import('@/views/events/add-edit.vue'),
      props: true,
      meta: {
        title: '修改活动',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/events/index',
      },
    },
    {
      path: 'view/:id',
      component: () => import('@/views/events/add-edit.vue'),
      props: (route: RouteLocation) => ({ id: route.params.id, view: true }),
      meta: {
        title: '查看活动',
        hideInMenu: true,
        ignoreCache: true,
        noAffix: true,
        activeMenu: '/events/index',
      },
    },
  ],
};

export default routes;
