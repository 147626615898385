import { defineStore } from 'pinia';
import { setToken, clearToken } from '@/utils/auth';
import { removeRouteListener } from '@/utils/route-listener';
import { type UserState } from './types';
import { $fetch } from '@/utils/fetch';
import { ref } from 'vue';

const useUserStore = defineStore('user', () => {
  const getInitUser = (): UserState => ({
    userId: undefined,
    name: undefined,
    photo: undefined,
    username: undefined,
    nickname: undefined,
    email: undefined,
    roles: [],
  });

  const user = ref<UserState>(getInitUser());

  const setInfo = (partial: Partial<UserState>) => {
    user.value = { ...user.value, ...partial };
  }

  const resetInfo = () => {
    user.value = getInitUser();
  }

  // Get user's information
  const fetchInfo = async () => {
    if (import.meta.env.VITE_MOCK_LOGIN === 'yes') {
      setInfo({
        userId: '1',
        name: '王立群',
        photo: 'https://lf1-xgcdn-tos.pstatp.com/obj/vcloud/vadmin/start.8e0e4855ee346a46ccff8ff3e24db27b.png',
        email: 'wangliqun@email.com',
        username: 'wangliqun',
        nickname: 'wlq',
        roles: ['admin'],
      });

      return {
        isSuccess: true,
        code: 0,
      };
    }
    else {
      const { isSuccess, data, msg, code } = await $fetch('/admin/v1/user/profile');

      if (isSuccess) {
        setInfo({
          userId: data.uid,
          name: data.name,
          photo: data.photo,
          email: data.email,
          username: data.username,
          nickname: data.nickname,
          roles: data.roles ?? [],
        });
      }

      return { isSuccess, msg, code: Number(code) };
    }
  }

  // Login
  const login = async (code: string, redirect: string) => {
    const { isSuccess, data, msg } = await $fetch('/auth/v1/transfer/token', {
      errorMessage: false,
      data: {
        code,
        redirect,
      },
    });

    if (isSuccess) {
      setToken(data.access_token);
    }
    else {
      clearToken();
    }

    return { isSuccess, msg };
  }

  const logoutCallBack = () => {
    resetInfo();
    clearToken();
    removeRouteListener();
  }

  // Logout
  const logout = async () => {
    await $fetch('/auth/v1/logout', {
      errorMessage: false,
    });

    logoutCallBack();
  }

  return { user, setInfo, resetInfo, fetchInfo, login, logout };
});

export default useUserStore;
